import Vue from "vue";
import Vuex from "vuex";

import auth from "./_auth";
import question from "./_question";
import exam from "./_exam";
import media from "./_media";
import company from "./_company";
import site from "./_site";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    question,
    exam,
    media,
    company,
    site,
  },
});

// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     exam: require('./_exam').default,
//     question: require('./_question').default,
//     grouped_question: require('./_grouped_question').default,
//     media: require('./_media').default,
//     section: require('./_section').default,
//     auth: require('./_auth').default
//   }
// })
