<template>
  <v-row>
    <v-col class="pt-0 pt-sm-6">
      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height: 60vh"
      >
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="!loadContent" class="mb-3">
        <v-container
          :style="{
            maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
          }"
        >
          <v-row>
            <v-col class="pa-0 pa-sm-3" cols="12">
              <v-card class="rounded-lg card-box-shadow">
                <v-card-text>
                  <v-row align="center" justify="space-between">
                    <v-col cols="12" md="7" xl="8">
                      <div class="text-h6 cl-black">
                        Redeem Voucher Kelas yang Sudah Anda Beli
                      </div>
                      <div class="pt-1 text-body-1 cl-slate">
                        Akses kelas anda melalui voucher yang anda dapat setelah membeli
                        kelas
                      </div>
                      <div class="text-body-1 cl-slate">
                        Dengan akun
                        <span class="font-weight-bold cl-black">{{ user.fullname }}</span>
                      </div>
                      <v-alert type="error" text v-show="response !== null">{{
                        response
                      }}</v-alert>
                    </v-col>
                    <v-col cols="12" md="5" xl="4">
                      <v-form
                        @submit.prevent="submit"
                        lazy-validation
                        ref="form"
                        v-model="valid"
                      >
                        <v-row no-gutters>
                          <!-- <v-col cols="12">
                            <v-textarea
                              no-resize
                              rows="2"
                              class="mr-3 mb-2"
                              style="width: 100%"
                              outlined
                              rounded
                              filled
                              hide-details="auto"
                              placeholder="Masukkan kode redeem (khusus user TPA Amikom)"
                              v-model="meta.pmo_voucher"
                              required
                            >
                            </v-textarea>
                          </v-col> -->
                          <v-col cols="12" class="d-flex">
                            <v-text-field
                              class="mr-3 mb-2"
                              outlined
                              rounded
                              filled
                              hide-details="auto"
                              placeholder="Masukkan kode voucher"
                              v-model="voucher"
                              required
                            >
                            </v-text-field>
                            <v-btn
                              color="primary"
                              elevation="0"
                              rounded
                              :disabled="!voucher"
                              @click="dialog = true"
                              >Redeem</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" class="py-6">
              <v-tabs class="transparent-tabs">
                <v-tab @click="getData(0)">Kelas Satuan</v-tab>
                <!-- <v-tab @click="getData(1)">Kelas Subskripsi</v-tab> -->
              </v-tabs>
            </v-col>
          </v-row>
          <!-- My class list -->
          <ListClass
            ref="listClassRef"
            :isSubscribe="isSubscribe"
            @loadContentTrue="loadContentTrue"
            @loadContentFalse="loadContentFalse"
          >
          </ListClass>
          <!-- end class list -->
        </v-container>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h6"> Perhatian </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left pt-2">
          <p class="text-h6 font-weight-medium">
            Anda yakin mau reedem voucher dengan akun
            <b>{{ user.fullname }}</b> dengan no HP. {{ user.phone }} ?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false" class="mb-3">Batal</v-btn>
          <v-btn
            color="primary"
            elevation="0"
            :loading="submitting"
            @click="submit"
            class="mb-3"
            >Ya, yakin!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ListClass from "./ListClass.vue";

export default {
  name: "my-class",
  data() {
    return {
      loadContent: false,
      meta: {
        pmo_voucher: "",
      },
      voucher: "",
      referral: "",
      valid: true,
      submitting: false,
      response: null,
      dialog: false,
      isSubscribe: 0,
    };
  },
  components: {
    ListClass,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.getData(0);
  },
  methods: {
    loadContentTrue() {
      this.loadContent = true;
    },
    loadContentFalse() {
      this.loadContent = false;
    },
    getData(val) {
      this.isSubscribe = val;
      this.$refs.listClassRef.getClass();
    },
    submit() {
      this.submitting = true;
      let meta = {
        pmo_voucher: this.meta.pmo_voucher,
      };
      let code = this.voucher.trim();
      let referral = this.referral.trim();
      if (code != "") {
        setTimeout(() => {
          this.axios
            .post(
              `/users/v1/myprogram/redeem`,
              { meta, code, referral },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(() => {
              this.submitting = false;
              this.getData(0);
              this.$refs.form.reset();
              this.dialog = false;
            })
            .catch((res) => {
              this.dialog = false;
              this.submitting = false;
              this.$refs.form.reset();
              this.response = res.response.data.message;
              setTimeout(() => {
                this.response = null;
              }, 5000);
            });
        }, 1000);
      } else {
        this.submitting = false;
        this.$refs.form.reset();
        this.response = true;
        this.response = "Kode Lisensi tidak boleh kosong";
        setTimeout(() => {
          this.response = null;
        }, 5000);
      }
    },
  },
};
</script>

<style type="text/css">
.text-dots-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
